.loading{
    width: auto;
    height: calc(100vh - 115px);
    background-color: white;
    display: grid;
    place-items: center;
    position: relative;
}
.loading > div{
    width: 6vmax;
    height: 6vmax;
    border-bottom: 4px dotted #F76B10;
    border-radius: 50%;
    animation: loadingRotate 1200ms linear infinite;
    position: absolute;
    top: 50%;
    left: 53%;
}
@keyframes loadingRotate{
    to{
        transform: rotateZ(360deg)
    }
}