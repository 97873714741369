@font-face {
    font-family: "inter-regular";
    src: url(../fonts/inter-regular.ttf);
}

@font-face {
    font-family: "inter-medium";
    src: url(../fonts/inter-medium.ttf);
}

@font-face {
    font-family: "inter-semibold";
    src: url(../fonts/inter-semibold.ttf);
}

@font-face {
    font-family: "inter-bold";
    src: url(../fonts/inter-bold.ttf);
}

* {
    box-sizing: border-box;
}

body {

    margin: 0;
    padding: 0;
    word-break: break-word;
}

/* login page css */

.login-spacing {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    /* padding-right: 202px; */
    /* height: calc(100vh - 30px); */
    align-items: center;
    height: calc(100vh - 0px);
}

.back-header {
    background: #ED753F;
    padding: 30px;
    position: relative;
}

.backheader-inner .back {
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    display: flex;
}

.back img {
    margin-right: 11px;
}


.d-flex {
    display: flex !important;
}

.login-img img {
    height: 92vh;
    width: 100%;
    object-fit: contain;
    display: flex;
}

.login-img {
    position: fixed;
    bottom: 0;

}

.login-col-2 {
    margin: auto;
    flex: 0 0 421px;
    max-width: 421px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box-main {

    border: 1px solid #cccccc;
    border-radius: 20px;
    flex: 0 0 421px;
    max-width: 421px;
    padding: 36px 35px;
    text-align: center;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

}

.logo img {
    height: 60px;
}

.login-space {
    margin-top: 17px !important;
    margin-bottom: 36px !important;
}

.login-title {
    /* font-family: 'inter-regular' !important; */
    font-family: 'inter-medium' !important;
    font-style: normal;
    font-size: 23px !important;
    line-height: 30px !important;
    color: #027C8A;
    font-weight: 700 !important;
}

.login-para.MuiTypography-body1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    font-family: 'inter-regular';
    margin-bottom: 26.5px;
}

.m-auto {
    margin: auto;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
    border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
    background: transparent;
    border: 1px solid #00000033;
    border-radius: 7px;
    height: 40px;
}
/* .date-picker-signup .MuiStack-root{
padding: 0;
width: 100%;
margin: 0;
}
.date-picker-signup .MuiStack-root .MuiInputBase-readOnly{
    border-radius: 7px;
    height: 40px;
}
.date-picker-signup .MuiStack-root .MuiInputBase-readOnly:hover fieldset{
    border: 1px solid #00000033;
} */

.password-inputdesign .MuiInputBase-adornedEnd {
    border-radius: 7px;
    height: 40px;
}

.input-design-div .input-placeholder input::placeholder {
    color: #000000;
    font-family: 'inter-regular';
    font-size: 14px;
    line-height: 21.78;
}

.password-inputdesign {
    margin-top: 0 !important;

}

.password-inputdesign input {
    font-size: 14px;
}

.input-design {
    margin-bottom: 13px !important;
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
    background: #ED753F;
    color: #fff;
    font-size: 14px;
    line-height: 29px;
    border-radius: 7px;
    box-shadow: none;
    min-height: 40px;
    font-family: 'inter-regular';
    text-transform: capitalize;
    letter-spacing: 0.5px;
    text-decoration: none !important;
}

.underline-none {
    text-decoration: none !important;
}

.green-btn button,
.green-btn button:hover {
    background: #027C8A !important;

}

.forgotpassword-main {
    margin-top: 51.5px;
    margin-bottom: 20px;
}

.forgotpassword-main .forgotpassword-a {
    color: #ED753F;
    border-bottom: 1px solid #ED753F;
    font-size: 16px;
    font-family: 'inter-regular';
    line-height: 18px;
    text-decoration: none !important;
}

.have-account-p {
    font-size: 14px !important;
    line-height: 18px !important;
    font-family: 'inter-medium' !important;
    color: #32324D;
}

.signup-text {
    color: #ED753F !important;
    cursor: pointer;
}

.resend-text {
    color: #027C8A !important;
    border-bottom: 1px solid #027c8a;
    margin-left: 4px !important;
}

.have-account {
    margin-bottom: 20px;
}

.login-btn button {
    width: 68.784%;
}

.auto-innercontent {
    height: calc(100vh - 83px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.mb-40 {
    margin-bottom: 40px;
}

/* end login page css */

.reset-box {
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    padding: 33px;
    max-width: 67%;
    width: 100%;
    max-width: 415px;
    margin: auto;
    text-align: center;
    box-shadow: 0px 5px 20px #1e1e2214;
}

.reset-box .reset-text {
    color: #027C8A;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 20px;
    font-family: 'inter-regular';
    font-weight: 700;
}

.reset-box .reset-inner-text {
    font-size: 14px;
    font-family: 'inter-medium';
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 24px;
}

.continue-btn button {
    width: 60%;
}

.reset-input {
    max-width: 80%;
    margin: auto;
}

.reset-input-design {
    margin-bottom: 32px !important;
    margin-top: 0 !important;
}

.receive-code-p {
    margin-top: 22px !important;
}

.verify-design-div form {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 15px;
}

.verify-design-div .verify-design {
    margin-top: 0 !important;
    margin: 7px;
}

.verify-design-div {
    max-width: 85%;
    margin: auto;
}


/* / 13-/4/23 start css / */
/* / payment css satrt  / */
.main-modal {
    border: 1px solid #CCCCCC !important;
    border-radius: 10px;
    padding: 33px !important;
    max-width: 480px;
    width: 100% !important;
    text-align: center;
    box-shadow: 0px 5px 20px #1e1e2214 !important;
    outline: none !important;
  
    
}

.modal-logo img {
    height: 60px;
}

.modal-text p {
    font-family: 'inter-bold';
    font-size: 25px;
    line-height: 27px;
    color: #027C8A;

}

.modal-space p {
    margin: 13px 0px 13px;
}

.input-top {
    margin-top: 0px !important;
}

.payment-modal {
    display: flex;
    max-height: calc(100vh - 160px);
    overflow: auto;
}

.modal-get-logo2 button {
    width: 20px !important;
    height: 20px !important;
    padding: 0 !important;
    align-items: center !important;
    display: flex;
    justify-content: flex-end;
}

.payment-modal .MuiPaper-rounded {
    padding: 0px !important;
    box-shadow: none !important;
}

.number-set p {
    color: #ED753F;
    font-size: 16px;
    /* font-family: 'inter-regular'; */
    font-family: 'inter-bold';
    line-height: 18px;
}

.amout-text p {
    font-family: 'inter-medium';
    font-size: 14px;
    line-height: 20px;
}

.payment-inner-text p {
    font-family: 'inter-regular';
    font-size: 11px;
    line-height: 20px;
}

.payment-pocilcy p {

    font-family: 'inter-regular' !important;
    font-size: 11px !important;
    line-height: 20px !important;
    color: #000000;
}

.payment-btn button {
    width: 100%;
}

.number-space p {
    padding: 10px 0px;
}

.payment-inner-text-space p {
    padding: 5px 0px;
}

.payment-pocilcy-space p {
    padding: 10px 0px;
}

.check-box-set {
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-check-box .MuiCheckbox-colorPrimary {
    color: #ED753F !important;
    padding: 0 10px 0px 0px !important;
}

.set-check-box svg {
    height: 18px !important;
    width: 18px !important;
}

.set-check-box {
    display: flex;
}

.getmodal {
    max-width: 700px !important;
}

.modal-get-logo img {
    height: 35px;
}

.set-getting-box {
    width: 100%;
}

.item-top {
    padding-top: 0px !important;
}

.set-select {
    width: 100%;
    margin: 0px !important;
}

.item-top .MuiSelect-outlined {
    background: transparent !important;
    border: 1px solid #00000033 !important;
    border-radius: 7px !important;
    padding: 7.5px 14px !important;
    text-align: left !important;
    font-size: 14px !important;
    font-family: 'inter-regular';
    color: #000000; 
    /* font-family: 'inter-regular' !important;
    color: currentColor !important; */
   
}
/* .select-text-font{
    font-size: 13px !important;
    font-family: 'inter-regular' !important;
    color: currentColor !important;
} */
.set-get-logo-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}

.modal-get-logo2 img {
    height: 10px;
}

.set-get-text-top {
    margin-top: -54px;
    padding-bottom: 30px;
}

.get-modal-text p {
    font-family: 'inter-bold';
    font-size: 20px;
    line-height: 20px;
    color: #027C8A;
}

.get-modal-text-inner p {
    font-family: 'inter-regular' !important;
    font-size: 12px !important;
    line-height: 20px !important;
}

.set-position-btn {
    position: absolute;
    top: 5px;
    right: 8px;
}

.add-btn,
.position-relative {
    position: relative;
}

.orange-btn-modal button,
.orange-btn-modal button:hover {
    background: #ED753F;
    color: #fff;
    font-size: 14px;
    line-height: 29px;
    border-radius: 7px;
    box-shadow: none;
    min-height: 30px;
    font-family: 'inter-regular';
    text-transform: capitalize;
    max-height: 17px;
}

/* / payment css satrt  / */
/* / 13-/4/23 end css / */
/* 17-4-23 */
.offer-main-modal {
    max-width: 378px !important;
}

.offer-text p {
    font-family: 'inter-regular';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    /* color: #78797F; */
    color: black;
}

.offer-btn {
    margin-bottom: 33px;
}

.offer-btn-both button {
    width: 100%;
    max-width: 100px !important;
}

.offer-btn-set {
    justify-content: space-around !important;
    display: flex;
}

.offer-modal-text p {
    font-size: 23px !important;
}

.accept-check-box-set {
    justify-content: left !important;
}

.accept-modal .MuiPaper-rounded {
    padding: 0px !important;
    box-shadow: none !important;
}

.accept-modal .MuiInputBase-colorPrimary {
    width: 100% !important;
}

.icon-arrow svg path {
    fill: #ED753F !important;
}

.delete-modal-text-inner p {
    font-family: 'inter-regular' !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: left !important;
}

.accept-modal-space {
    margin: 10px 0px 5px !important;


}

.res-set-modal {
    max-height: calc(100vh - 210px);
    overflow: auto;
    padding: 0 10px;
}

.getting-started {
    max-height: calc(100vh - 160px);
    overflow: auto;
    padding: 0 10px;
}

.visa-modal-logo {
    position: absolute;
    top: -46px;
    right: 7px;
}

.visa-modal-logo img {
    border: 1px solid #00000033;
    border-radius: 7px;
    padding: 8px 10px;
}

.visa-btn-main {
    position: relative;
    margin-top: 0px !important;
}

.set-select svg path {
    fill: #ED753F !important;
}

.view-edit {
    text-align: right !important;
}

.view-edit button {
    width: 20px !important;
    height: 20px !important;
    padding: 0px !important;
    min-width: 0px !important;
}

.view-edit img {
    height: 10px !important;
}

.set-edit-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.orange-btn-edit button,
.orange-btn-edit button:hover {
    /* min-height: 20px !important; */
    padding: 2px 16px !important;
    line-height: 22px !important;
    margin-left: 17px;
    width: max-content;
}

.viewedit-text p {
    text-align: left;
    color: #000000;
    font-size: 14px;
    font-family: 'inter-bold';
    line-height: 18px;
    margin-bottom: 5px;
}

.viewedit-number p {
    text-align: left;
    color: #ED753F;
    font-size: 12px;
    line-height: 18px;
    font-family: 'inter-regular';
}

.set-textarea-box {
    height: 100px !important;
    overflow: hidden;
    width: 100% !important;
    resize: none;
    border-color: #00000033;
    border-radius: 7px;
    outline: none !important;
    padding: 10px 15px;
    font-family: 'inter-regular';
}

.set-textarea-box-top {
    margin-top: 10px;
}

.set-text-area {
    margin-top: 10px;
}

/* 
.input-design-div-border .MuiFilledInput-underline,
.input-design-div-border .MuiFilledInput-underline:hover,
.input-design-div-border .MuiFilledInput-underline.Mui-focused {
    border: 1px solid #ed753f !important;
}

.input-design-div-border .input-placeholder input::placeholder {
    color: #ED753F !important;
} */

.accept-btn2 {
    margin: 15px 0px;
}

.reject-btn button {
    background-color: #027C8A !important;
}

.view-modal {
    max-height: calc(100vh - 30px);
    overflow: auto;
}

.orange-btn-edit-inner {
    position: absolute;
    right: 6px;
    top: 6px !important;
    margin-top: 0px !important;
}

.design-position {
    position: relative;
}

/* / 17/4/23 end / */
/* // 18-4-23 start / */
.bookking-view-btn {
    margin: 20px 0px 0px;
}

.input-design-div-booking .MuiFilledInput-underline,
.input-design-div-booking .MuiFilledInput-underline:hover,
.input-design-div-booking .MuiFilledInput-underline.Mui-focused {
    background: transparent;
    border: 1px solid #00000033;
    border-radius: 20px !important;
    height: 25px !important;
}

.booking-text-set p {
    text-align: left !important;
    margin-bottom: 2px;
}

.booking-text-inner {
    margin-bottom: 10px;
}

.booking-text-set span {
    color: #ED753F;
}

.drop-text p {
    max-width: 300px;
    margin: auto;
}

.drop-select {
    position: relative;
}

.set-drop-box {
    background: #e6e6e6;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    max-height:188px;
    min-height:188px
    /* / padding: 50px 0px; / */
}

.drop-set-btn {
    position: absolute !important;
    top: 20px;
    left: 0;
    right: 0;
}

.drop-set-btn img {
    height: 30px;
}

.drop-top {
    max-width: 269px;
    /* margin: 20px auto; */
    margin: 13px auto;
}

.businessimage-main-modal {
    max-width: 480px !important;
}

.businessimage-text p {
    font-size: 12px;
    line-height: 28px;
    max-width: 230px;
    margin: auto;
    font-family: 'inter-regular';
}

.upload-btn {
    margin-top: 9px;
}

.average-text p {
    font-size: 12px !important;
    line-height: 24px !important;
}

.input-design-div2 .MuiFilledInput-underline,
.input-design-div2 .MuiFilledInput-underline:hover,
.input-design-div2 .MuiFilledInput-underline.Mui-focused {
    /* border: 1px solid #ed753f !important; */
    max-width: 269px;
    margin: 0px auto 20px;

}

.input-design-div2 .input-placeholder input::placeholder {
    text-align: center;
}

.drop-set-btn2 {
    top: 45px !important;
}

.set-drop-box2 {
    min-height: 130px !important;

}

/* / width / */
.main-modal-scroll::-webkit-scrollbar {
    width: 10px;
}

/* / Track / */
.main-modal-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* / Handle / */
.main-modal-scroll::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

/* / Handle on hover / */
.main-modal-scroll::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

.main-modal-scroll-set {
    margin-left: -10px;
    margin-right: -10px;
}

.set-select .MuiInputBase-colorPrimary fieldset {
    border-color: transparent !important;
}

.set-textarea-box-top textarea::placeholder {

    font-size: 12px;
    font-family: 'inter-regular';

}

/* / 18-4-23 end / */
/* / 20-4-23 start / */
.createoffer-modal-space p {
    margin: 0px 0px 30px;
}

.create-offer-text p {
    color: #120D26;
    font-size: 12px;
    line-height: 24px;
    font-family: 'inter-bold';
    text-align: left;
}

.fire-icon-set {
    display: flex;
    position: absolute;
    top: -41px;
    right: 9px;
}

.fire-icon-main {
    position: relative;
}

.fire-icon-main-img {
    margin-right: 5px;
}

.fire-select svg path {
    fill: #CCCCCC !important;
}

.set-fire-top {
    margin-top: 0px !important;
}

.fire-inner-text p {
    font-family: 'inter-regular';
    font-size: 9px;
    line-height: 12px;
}

.fire-code {
    margin-top: 50px;
}

.plus-text p {
    color: #027C8A !important;
    text-align: left !important;
    font-weight: 700;
}

.plus-text-inner p {
    text-align: left !important;
}

.fire-btn-set {
    margin: auto !important;
}

.set-fire-top-set {
    display: flex;
}

.fire-bottom-set {
    margin-bottom: 5px;
}

.fire-icon-set img {
    width: 16.5px;
    height: 16.5px;
    object-fit: contain;
}

.set-drop-box2 input {
    position: absolute;
    left: 0;
    height: 100%;
    right: 0;
    z-index: 99;
    opacity: 0;
    cursor: pointer;
}

.set-drop-box input {
    position: absolute;
    left: 0;
    height: 100%;
    right: 0;
    z-index: 99;
    opacity: 0;
    cursor: pointer;
}

.res-set-modal2 {
    max-height: calc(100vh - 110px) !important;
    overflow: auto;
    padding: 0px 10px;
}

.set-date-picker .MuiInputBase-colorPrimary {
    background: transparent;
    border: 1px solid #00000033 !important;
    border-radius: 7px;
    height: 40px !important;
    padding: 6.5px 0px !important;
    /* max-width: 224px !important; */
    width: 100%;
    z-index: 9;
}

.set-date-picker fieldset {
    border-color: transparent !important;
}

.set-date-picker input {
    padding: 6.5px 14px !important;
    color: #120D26;
    font-size: 12px !important;
    line-height: 24px;
    font-family: 'inter-regular' !important;
    text-align: left;
    cursor:pointer;

}

.time-picker input {
    color: #120D26;
    font-size: 12px !important;
    line-height: 24px;
    font-family: 'inter-regular' !important;
    text-align: left;
}

.set-date-picker .MuiStack-root {
    padding-top: 0px !important;
}

.set-calendar {
    position: absolute;
    right: 13px;
    top: 10px;
}

.set-calendar-position {
    position: relative;
}

.set-calendar svg path {
    fill: #ed753f !important;
}

.drop-top-logo {
    max-width: 177px !important;
}

.res-create-offer-text p {
    white-space: nowrap;
}

.orange-btn-edit-inner button {
    min-height: 29px !important;

}

.set-view-edit-btn {
    display: flex;
    width: 100%;
}

.set-view-edit-btn .viewedit-number2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* align-items: center; */
}

/* .input-design-div-people .MuiInputBase-colorPrimary {
    width: 86.5%;
} */

.viewedit-text-set-width {
    width: 100%;
}

.set-calendar-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
    width: 100%;
}




.viewedit-number-set-btn {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.set-flex {
    display: flex;
}

/* / 20-4-23 end / */


.sidebar-content {
    width: 275px;
    height: 100vh;
    background: #fff;
    box-shadow: 20px 4px 120px #65648226;
    position: fixed;
    left: 0;
    z-index: 99;
}

.back-f5.MuiBox-root.css-0 {
    background: #fbfbfb;
}

.side-logo {
    height: 54px;
    padding: 0px 24px;
    margin: auto;
}

.sidebar-logo {
    padding-top: 20px;
    padding-bottom: 30px;
}

.sidebar-ul {
    padding: 0;
    overflow: auto;
    width: 100%;
    height: calc(100vh - 180px);
}

.sidebar-ul li .dash-inner-li {
    display: flex;
    width: 100%;
}

.sidebar-ul li {
    /* background: #f5f5f5a6; */
    border-bottom: 1px solid #EDEDF6;
    padding: 18px 15px 18px 35px;
    border-right: 3px solid transparent;
    letter-spacing: 0.03em;
}

.sidebar-ul li.active {
    border-right: 4px solid #ED753F;
    background: #FBFBFB;
}

.dash-img {
    margin-right: 12px;
    width: 14px;
    height: 21px;
    object-fit: contain;
}

.sidebar-ul a li .dash-inner-li .side-title {
    font-size: 14px;
    font-family: 'inter-medium';
    line-height: 21px;
    font-weight: 500;
    color: #24233D;
}

.sidebar-ul li.active .dash-inner-li .side-title {
    font-weight: 600;

}

.support.MuiBox-root.css-0 {
    position: fixed;
    background: #027C8A;
    width: 275px;
    left: 0;
    color: #ffff;
    right: 0;
    padding: 15px;
    bottom: 12px;
    display: flex;
    justify-content: center;
    font-family: 'inter-medium';
}

/* width */
.sidebar-ul::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.sidebar-ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.sidebar-ul::-webkit-scrollbar-thumb {
    background: #027c8a;
}

/* Handle on hover */
.sidebar-ul::-webkit-scrollbar-thumb:hover {
    background: #03707c96;
}

.noti-round {
    align-items: center;
    justify-content: center;
    background: #ED753F;
    color: #fff;
    border-radius: 50%;
    padding: 2px;
    height: 20px;
    line-height: 14px;
    font-size: 12px;
    display: flex;
    width: 20px;
}

.content-main {
    min-height: 100vh;
    margin-left: 275px;
    width: calc(100% - 275px);
    padding: 0 25px;
}

.dash-row {
    flex-wrap: wrap;
    display: flex;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
}

.dash-col {
    width: calc(100% / 3);
    padding: 0 15px;
}

.dash-row .dash-col .dash-card {
    padding: 20px 25px 30px;
    height: 100%;
}

.dash-row .dash-col:nth-child(odd) .dash-card {
    background: #ED753F;
}

.dash-row .dash-col:nth-child(even) .dash-card {
    background: #027C8A;
}

.dash-card-top {
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
}

.dashcheck-img {
    height: 60px;
}

.dashcard-num {
    font-size: 35px !important;
    font-family: 'inter-semibold' !important;
    color: #fff;
    line-height: 35px !important;
}

.dashcard-text {
    font-size: 16px !important;
    color: #fff;
    margin-top: 5px !important;
    letter-spacing: 0.03em !important;
    font-family: 'inter-medium' !important;
}

.dash-topspace {
    padding-top: 28px;
}

.voucher-topspace {
    margin-top: 30px;
    margin-bottom: 30px;
}

.dash-border {
    border-bottom: 1px solid #E6E6E6;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.dash-video-image {
    height: 170px;
    width: 100%;
    object-fit: cover;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.orange-text {
    color: #F76B10;
    font-size: 15px !important;
    line-height: 24px !important;
    font-family: 'inter-semibold' !important;
    text-transform: uppercase;
    margin-bottom: 20px !important;
    letter-spacing: 1px;
}

.text-center {
    text-align: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.white-btn,
.white-btn:hover {
    background: #fff !important;
    color: #000 !important;
    padding: 8px 30px !important;
    min-height: 40px !important;
    font-size: 13px !important;
    line-height: 21px !important;
    border-radius: 7px !important;
    border: 1px solid #EAEAEF !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font-family: 'inter-regular' !important;
}

.download-btn {
    margin-top: 40px !important;
    margin-bottom: 60px !important;
}

.offercreation-sec img {
    height: 290px;
    object-fit: contain;
}

.offercreation-sec {
    border-bottom: 1px solid #e6e6e6;
}

.create-btn {
    width: max-content !important;
    margin: auto
}

.create-max {
    max-width: 490px;
    text-align: center;
    margin: auto;
    padding-top: 20px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-20 {
    padding-top: 20px;
}

.create-title {
    color: #120D26;
    font-size: 18px !important;
    line-height: 25px !important;
    margin-bottom: 20px !important;
    font-family: 'inter-semibold' !important;
}

.book-row {
    display: flex;
    width: 100%;
    border: 1px solid #ED753F;
    padding: 15px 20px;
    border-radius: 6px;
    margin-bottom: 20px;
    align-items: center;
    background: #fff;

}

.offer-noti-row,
.post-req-row {
    padding: 10px 20px !important;
    min-height: 53px;
}

.offer-noti-row .book-col {
    width: 20%;
    flex: 0 0 19.59%;
    max-width: 20%;
}

.flex-end {
    display: flex !important;
    justify-content: flex-end !important;
}



.book-col {
    width: 20%;
    flex: 0 0 19.59%;
    max-width: 20%;
}



.book-col6 {
    width: 20px;
    flex: 0 0 20px;
    max-width: 20px;
}

.table-dot {
    background: #ED753F;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
}

.h-100 {
    height: 100%;
}

.create-offer-section .create-sec-text {
    font-size: 14PX;
    line-height: 25px;
    font-family: 'inter-regular';
    margin-bottom: 30px;
    color: #747688;
}

.create-btn button,
.create-btn button:hover {
    min-height: 40px;
    padding: 5px 20px;
}

.book-row .book-col p {
    font-family: 'inter-semibold';
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    color: #120D26;
}

.book-row .book-col p.canceled-text {
    color: #027C8A;
}

.book-col.req-col5.MuiBox-root.css-0 {
    width: 27%;
    flex: 1;
    max-width: 30%;
    justify-content: flex-end;
    display: flex;
}

.accept-btn button,
.accept-btn button:hover {
    min-height: 30px !important;
    line-height: 19px !important;
    margin-left: 5px;
    font-size: 13px;
    padding: 0 12px;
}

.book-col.req-col4 {
    width: 12%;
    max-width: 12%;
    flex: 0 0 12%;
}



.res-set-modal {
    max-height: calc(100vh - 210px);
    overflow: auto;
}

.post-req-col2 {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.post-req-col3 {
    width: 10.2%;
    flex: 0 0 10.2%;
    max-width: 10.2%;
}

.post-req-col1 {
    width: 40%;
    flex: 0 0 40%;
    max-width: 40%;
}

.seatcreation-tab-main {
    padding-top: 20px;
    padding-bottom: 20px;
}

.seatcreation-inner-tab {
    background: #F6F6F6;
    width: max-content;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 16px;
    border-bottom: 0 !important;
}

.seatcreation-tab button.Mui-selected {
    border: 0 !important;
    background: #fff;
    color: #F76B10;
    border-radius: 12px;
}

.seatcreation-tab .MuiTabs-scroller .MuiTabs-indicator {
    background: no-repeat;
}

.seatcreation-tab button {
    font-size: 12px;
    line-height: 24px;
    font-family: 'inter-semibold';
    padding: 8px 22px;
    border: 0 !important;
    min-height: 40px;
    letter-spacing: 1px;
    margin-right: 10px;
}

.seatcreation-tab button:last-child {
    margin-right: 0;
}

.seatcreation-inner-tab .seatcreation-tab {
    min-height: 40px;
}

.profile-main {
    padding-top: 20px;
    padding-bottom: 20px;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-card {
    background: #fff;
    /* padding: 20px; */
    border: 1px solid #cccccc;
    border-radius: 12px;
}

.profile-div .MuiPaper-elevation {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.input-design-div input {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    font-family: 'inter-regular';

}

.profile-div {
    padding: 20px;
    border-bottom: 1px solid #E6E6E6;
}

.profile-btn {
    white-space: nowrap;
    margin-left: 10px;
}

.visalogo {
    border: 1px solid #cccccc;
    border-radius: 4px;
    position: absolute;
    padding: 4px 3px;
    right: 45px;
    top: 9px;
    width: 33px;
    object-fit: contain;
    height: 22px;
}

.deletelogo {
    position: absolute;
    padding: 6px 3px;
    right: 9px;
    top: 5px;
    height: 30px;
    object-fit: contain;
}

.profile-col1,
.profile-col2,
.profile-col3 {
    width: 20%;
    flex: 0 0 27%;
    max-width: 40%;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.seat-card {
    background: #fff;
    padding: 20px 20px 12px;
    overflow: auto;
}

.seat-create-main {
    padding-top: 24px;
}

.seat-row {
    display: flex;
    width: 100%;
    align-items: center;
}

.seat-col {
    width: calc(100% / 3);
}

.seat-col1 {
    width: 100%;
    flex: 1;
    max-width: 100%;
}

.seat-col3 {
    flex: 0 0 17%;
    width: 17%;
    max-width: 17%;
}

.seat-col2 {
    flex: 0 0 40%;
    width: 100%;
    max-width: 23%;
}

.seat-three-main .css-19kzrtu {
    padding: 0;
}

.seat-input input {
    width: 37px;
}

.seat-input .input-design {
    margin-bottom: 0 !important;
}

.seat-border {
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 8px;
    padding-top: 8px;


}

.seat-input .MuiFilledInput-underline {
    height: 30px !important;
}

.seat-to {
    color: #027c8a;
    margin: 0 7px !important;
    font-family: 'inter-semibold' !important;
    font-size: 14px !important;
    white-space: nowrap;
}

.seat-head p {
    font-family: 'inter-semibold';
    font-size: 14px;
    width: max-content;
    text-align: center;
}

.seat-main {
    display: flex;
    align-items: center;
}

.increment,
.decrement {
    min-width: 24px !important;
}

.seat-number {
    min-width: 21px;
    text-align: center;
    font-size: 13px !important;
    font-family: 'inter-semibold' !important;
    color: #027c8a;
}

.chair-image {
    margin-right: 5px;
    height: 24px;
}

.seat-card .seat-row:last-child {
    border-bottom: 0;
}

.save-change-btn {
    padding-top: 20px;
    display: flex;
    justify-content: end;
}

.header-responsive {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-responsive-main {
    padding: 20px 24px;
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
}

.reslogo {
    height: 54px;
}

.menuimageres {
    height: 20px;
}

.sidebar-ul a {
    text-decoration: none !important;
}

.menuimageres path {
    fill: black;
}

.p-0 {
    padding: 0 !important;
}

.cus-calendar .rbc-month-view {
    background: #fff;
    border: 1px solid #DFE0E4;
}

.cus-calendar .rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #DFE0E4;
}

.cus-calendar .rbc-off-range-bg {
    background: #f6f6f6;
}

.cus-calendar .rbc-header {
    border-bottom: 0;
    font-family: 'inter-medium';
    color: #888B8F;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;

}

.cus-calendar .rbc-date-cell {
    text-align: center;
}

.cus-calendar .rbc-today {
    background-color: #ed753f75;
}

.cus-calendar .rbc-toolbar button {
    background: #fff;
    border: 0;
    border-radius: 5px !important;
    margin: 0 2px;
    font-family: 'inter-regular';
}

.cus-calendar .rbc-toolbar button:active,
.cus-calendar .rbc-toolbar button.rbc-active {
    background: #ed753f !important;
    color: #ffff !important;
    box-shadow: none;
}

.cus-calendar .rbc-toolbar .rbc-toolbar-label {
    font-family: 'inter-regular'
}

.cus-calendar .rbc-toolbar button:hover {
    border-color: #adadad;
}

.w-100 {
    width: 100% !important;
}

.set-modal-back .MuiModal-backdrop {
    background-color: rgb(0 0 0 / 37%);

}

.seat-modal {
    max-width: 767px !important;
    max-height: calc(100vh - 30px);
}

.modal-seat-col3 {
    flex: 0 0 23%;
    width: 100%;
    max-width: 23%;
}

.seat-modal-card {
    overflow: auto;
    max-height: calc(100vh - 80px);
}

.login-col-1 {
    flex: 0 0 40%;
    max-width: 40%;
    width: 100%;
}

.getting-started .MuiGrid-container {
    margin-top: 0;
}

.getting-started .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 0;
}

.signup-btn {
    color: #ED753F !important;
    text-transform: capitalize !important;
    letter-spacing: normal !important;
    padding: 0px !important;
}

.menulogo button {
    width: max-content;
    min-width: auto;
}

.cus-calendar .rbc-label {
    color: #828588;
    font-family: 'inter-regular';
    font-size: 15.5px;
}

.profile-btn button {
    min-width: 91px;
}

.seat-time-picker .MuiInputBase-colorPrimary {
    height: 30px !important
}

.seat-time-picker input {
    padding: 6.5px 7px !important;
}

.select-design em {
    color: #b4b4b4;
    font-style: normal;
    font-family: 'inter-regular';
}

.cus-calendar .rbc-event,
.cus-calendar .rbc-day-slot .rbc-background-event {
    background-color: #ed753f;
    border-radius: 0 !important;
}

.visa-padding input {
    padding-right: 60px !important;
}

.seat-row-main .seat-border:last-child {
    border-bottom: 0 !important;
}

/* width */
.seat-modal-card::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.seat-modal-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.seat-modal-card::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

/* Handle on hover */
.seat-modal-card::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

.seat-card-overflow {
    padding: 0 10px;
}

.password-inputdesign fieldset {
    border: 1px solid #00000033 !important;
}

.cus-calendar .rbc-ellipsis,
.cus-calendar .rbc-show-more,
.cus-calendar .rbc-row-segment .rbc-event-content,
.cus-calendar .rbc-event-label {
    font-size: 12px;
    text-align: center;
}

.input-design-div3 input {
    text-align: center;
}

.btn-row {
    justify-content: space-between;
}

.btn-row .payment-btn {
    width: 48.5%;
}

.party-size-text {
    display: flex;
    justify-content: space-between;
}
.reg-col-2 {
    margin:auto 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reg-box-main {
    border: 1px solid #cccccc;
    border-radius: 20px;
    /* flex: 0 0 421px; */
    /* max-width: 421px; */
    padding: 36px 35px;
    text-align: center;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.reg-get-logo img {
    height: 55px;
    width: 215px;
}
.MuiTextField-root .MuiFormHelperText-sizeMedium,
.password-inputdesign .MuiFormHelperText-sizeMedium{
    margin-left: 5px;
}
.loader-set span{
    width: 20px !important;
    height: 20px !important;
    color: white !important;
}
.backheader-inner button {
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    text-transform: capitalize;
    padding: 0px !important;
}
#opening-time-label::before{
    content: "Opening time";
    color :#aeaeae;
    font-family: 'inter-regular' !important;
    font-size: 13.5px !important;
    font: inherit;
}

#closing-time-label::before{
    content:"Closing time";
    font: inherit;
    color :#aeaeae;
    font-family: 'inter-regular' !important;
    font-size: 13.5px !important;
}
.upload-img-box{
    width: 268px;
    border-radius: 10px;
    height: 188px;
}
.drop-img-box{
    height: 124px;
    border-radius: 10px;
    width: 100%;
}
/* .input-design-div3 .Mui-error
{
    margin-top: -17px;
    margin-left: 20px;
} */
.drop-validation p {
    margin-left: 45px !important;
}

.input-design-div-drop .MuiFilledInput-underline, .input-design-div-drop .MuiFilledInput-underline:hover, .input-design-div-drop .MuiFilledInput-underline.Mui-focused {
    margin: 0px auto 0px !important;
}
.set-mt-20{
    margin-top: 20px !important;
}
.disable-seat-pointer
{
    pointer-events: none;
    opacity: 0.9;
}
.api-loader
{
    display: flex;
    justify-content: center;
}
.dash-video-inner{
    margin-top: 38px;
}
.dash-video-inner-set{
    margin-bottom: 0px !important;
}
.validation-input-box{
    margin-bottom: 0px !important;
}
.set-pay-btn-box{
    min-width: 41% !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.date-input1 input{
    width:135px;
}

input {
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-position: center right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    /* background-color: red; */
    height:30px;
}
.input-time {
    min-width: 174px !important;
}

.offer-transaction-table{
    border: 1px solid #F76B10;
    border-radius: 13px !important;
}
.pagination-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 27px;
    margin-right: 20px;
}
.pagination-main .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color:#F76B10;
    color: white;
}
.offer-transaction-table table th {
    font-family: 'inter-semibold';
    font-size: 14px;
    line-height: 21px;
    color: #120D26;
}
table.live-offer-set th:nth-child(1), table.live-offer-set tr td:nth-child(1){
    width: 15% !important;
}
table.live-offer-set th:nth-child(2), table.live-offer-set tr td:nth-child(2) {
   width: 16% !important;
}
table.live-offer-set th:nth-child(3), table.live-offer-set tr td:nth-child(3){
   width: 11% !important;
}
table.live-offer-set th:nth-child(4), table.live-offer-set tr td:nth-child(4) {
   width: 11% !important;
}
table.live-offer-set th:nth-child(5), table.live-offer-set tr td:nth-child(5) {
   width: 7% !important;
}
.live-offer-btn.accept-btn button,.live-offer-btn .accept-btn button:hover {
   min-height: 40px !important;
   min-width: 100px !important;
   width: 95% !important;
}
.set-live-offer-btn{
    justify-content: initial !important;
}
.title-search-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.title-search-div .orange-text {
    margin-bottom: 0 !important;
}
/* .search-div svg {
    fill: #027c8a;
} */
/* .main-modal2 {
    outline: none !important;
} */
.payment-modal-icon{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.payment-modal-icon img{
    width: 20px;
    height: 20px;
}
/* width */
.crate-offer-text::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
 .crate-offer-text::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .crate-offer-text::-webkit-scrollbar-thumb {
    background: #c1c1c1; 
  }
 
   .crate-offer-text{
    overflow: auto;
    cursor: auto;
   }
   .how-toadd-video{
    object-fit:cover;
   }
   .input-error-party-szie .Mui-error  {
    font-weight: 400 !important;
    font-family: 'inter-regular' !important;
    font-size: 12px !important;
   }
   